.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.big {
  font-size: 24px;
}

.pink {
  background-color: rgb(235, 189, 189);
}

.blue {
  background-color: rgb(196, 234, 255);
}

.white {
  background-color: white;
}

.grey {
  background-color: #ddd;
}

.green {
  background-color: rgb(198, 230, 198);
}

.pretty {
  border: 2px solid black;
  border-radius: 3px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.inline-block {
  display: inline-block;
}

.margin-right {
  margin-right: 5px;
}

.shadow {
  -webkit-box-shadow: 5px 5px 0px -3px #000000; 
  box-shadow: 5px 5px 0px -3px #000000;
}

.pointer {
  cursor: pointer;
}
